import DOMPurify from "isomorphic-dompurify";

export const DataRequestLink = "https://dr.kymeso.net";

export const links = [
  { label: "Home", link: "/" },
  { label: "About", link: "/about" },
  { label: "KY Climate", link: "/topic" },
  { label: "Mesonet Climate", link: "/mesonet-climate" },
  { label: "Severe Weather Climate", link: "/severe-weather-climate" },
  { label: "Research", link: "/research" },
  { label: "Data Request", link: DataRequestLink },
  { label: "Contact", link: "/contact" },
  //   { label: "Apply", link: "/" },
];

export const sublinks = [
  { label: "Agriculture", link: "/agriculture" },
  { label: "Emergency Management", link: "/emergency-management" },
  { label: "Hydrology", link: "/hydrology" },
  { label: "Public Health", link: "/public-health" },
  { label: "Transportation", link: "/transportation" },
  { label: "Wildfire", link: "/wildfire" },
  { label: "Drought", link: "/drought" },
  { label: "KY Mesonet", link: "https://www.kymesonet.org" },
];

export const legalLinks = [
  { label: "Terms & Conditions", link: "/terms-and-conditions" },
  { label: "Data Request Policy", link: "/data-request-policy" },
];

export const ConvertPathText = (pathText) => {
  return pathText
    .replace(/-/g, " ")
    .replace(/_/g, " ")
    .toUpperCase()
    .split(" ")
    .map((i) => String(i)[0] + String(i).toLowerCase().slice(1)).join(" ");
}


export const getContentFromHtml = (content, length = 50) => {
  // Define the minimum length for a long text
  const minLength = length;
  // Get the HTML code as a string
  const htmlString = DOMPurify.sanitize(content);
  // Create a new HTML element to store the HTML code
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;
  // Get all text nodes in the HTML code
  const textNodes = [];
  const walk = document.createTreeWalker(tempElement, NodeFilter.SHOW_TEXT, null, false);
  let node;
  while (node = walk.nextNode()) {
    textNodes.push(node);
  }
  // Find the first long text node
  let longTextNode = null;
  for (let i = 0; i < textNodes.length; i++) {
    if (textNodes[i].textContent.length >= minLength) {
      longTextNode = textNodes[i];
      break;
    }
  }

  // Return the long text as a string
  const longText = longTextNode ? longTextNode.textContent.trim() : '';
  return longText;
}